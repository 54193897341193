import type { GroupData } from "$bindings/api/GroupData"
import type { GroupMemberProfile } from "$bindings/api/GroupMemberProfile"
import type { Permissions } from "$bindings/group/Permissions"
import type { RTCStatus } from "$bindings/group/RTCStatus"
import type { UserId } from "$bindings/group/UserId"
import type { PlayerItemInfo } from "$bindings/player/PlayerItemInfo"
import type { UserData } from "./model/IUser"

export const USER_SUBSTITUTE: UserData = {
    type: "Visitor",
    name: "Unknown User",
}

export const GROUP_MEMBER_SUBSTITUTE: GroupMemberProfile = {
    type: "Guest",
    last_seen_at: null,
}

export const PLAYER_ITEM_SUBSTITUTE: PlayerItemInfo = {
    image: "",
    title: "Unable to load video details",
    author: "The video player still works",
    details: {
        publish_date: null,
        duration: null,
        views: null,
        likes: null,
        definition: null,
    },
}

/// The substitute to show, when a status is unknown for the user.
export const RTCSTATUS_SUBSTITUTE: RTCStatus = {
    is_deaf: true,
    is_muted: true,
    is_camera: false,
    is_screen: false,
}

/// The statue the client should start with when connecting.
export const RTCSTATUS_DEFAULT: RTCStatus = {
    is_deaf: false,
    is_muted: false,
    is_camera: false,
    is_screen: false,
}

export const GROUP_PROFILE_SUBSTITUTE: GroupData = {
    id: "",
    owner: null,
    created_at: "0",
    image: "",
    name: "Unknown Group",
    // shortname: null,
    description: "Unknown Description",
}

export const USER_ID_UNKNOWN: UserId = { type: "Account", content: "" }

export const PERMISSIONS_SUBSTITUTE: Permissions = {
    admin: {
        group_profile_modify: true,
        group_permissions_modify: true,
        clients_kick: true,
        clients_poke: true,
        members_set_member_type: true,
        group_join: true,
        group_join_request: true,
        group_join_request_answer: true,
    },
    member: {
        group_profile_modify: false,
        group_permissions_modify: false,
        clients_kick: false,
        clients_poke: true,
        members_set_member_type: false,
        group_join: true,
        group_join_request: true,
        group_join_request_answer: true,
    },
    guest: {
        group_profile_modify: false,
        group_permissions_modify: false,
        clients_kick: false,
        clients_poke: true,
        members_set_member_type: false,
        group_join: true,
        group_join_request: true,
        group_join_request_answer: false,
    },
}
